// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-good-governance-india-foundation-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\good-governance-india-foundation.js" /* webpackChunkName: "component---src-pages-good-governance-india-foundation-js" */),
  "component---src-pages-index-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-team-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\refund_policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-technology-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-travel-and-tourism-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\travel-and-tourism.js" /* webpackChunkName: "component---src-pages-travel-and-tourism-js" */),
  "component---src-pages-urban-development-js": () => import("E:\\Projects\\Umang\\fairfest-gatsby\\src\\pages\\urban-development.js" /* webpackChunkName: "component---src-pages-urban-development-js" */)
}

exports.data = () => import("E:\\Projects\\Umang\\fairfest-gatsby\\.cache\\data.json")

